import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onboardDeveloper } from "../../../apis/ProjectApi";
import * as Yup from "yup";
import { getProjects } from "../../../Redux/Slices/Project/ProjectsSlice";
import { toast } from "react-toastify";
import api from "../../../apiclient";

const OnboardDialog = ({ open, onClose, projectId, projectStartDate }) => {
  const { developersProject } = useSelector((state) => state?.lists);
  const fullTimebillingType = [
    "Billable",
    "Non Billable",
    "Fixed Scope Direct",
    "Fixed Scope Indirect",
  ];
  const partTimebillingType = [
    "Billable",
    "Non Billable",
    "Fixed Scope Direct",
    "Fixed Scope Indirect",
  ];
  const boardingType = ["Onboarded", "OnReplaced", "OnObservation"];
  const [partTimeDeveloper, setPartTimeDeveloper] = useState([]);
  const [fullTimeDeveloper, setFullTimeDeveloper] = useState([]);
  const [FullTimeBillingData, setFullTimeBillingData] = useState();
  const [PartTimeBillingData, setPartTimeBillingData] = useState();

  const formik = useFormik({
    initialValues: {
      fullTimeUserId: [],
      partTimeUserId: [],
      onBoardingDate: "",
      fullTimebillingType: "",
      partTimebillingType: "",
      boardingType: "",
    },
    validationSchema: Yup.object({
      boardingType: Yup.string().required("Required"), // Add validation for boardingType
    }),
    onSubmit: async (values) => {
      values.userId = values.userId?.map((data) => data?._id);
      values.projectId = projectId;
      onboardDeveloper(values)
        .then((res) => {
          toast(res?.message);
          setTimeout(() => {
            dispatch(getProjects("Active"));
            closeHandler();
          }, 1000);
        })
        .catch((error) => toast(error?.response?.data?.message));
    },
  });

  const closeHandler = () => {
    formik.resetForm();
    onClose();
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const FetchFullTimeData = async () => {
      const res = await api.get(
        `api/v2/users/list?engagement_type=FullTime&projectId=${projectId}`
      );
      setFullTimeDeveloper(res?.data?.data);
      return res?.data;
    };

    const FetchPartTimeData = async () => {
      const res = await api.get(
        `api/v2/users/list?engagement_type=PartTime&projectId=${projectId}`
      );

      setPartTimeDeveloper(res?.data?.data);
      return res?.data;
    };

    FetchFullTimeData();
    FetchPartTimeData();
  }, [projectId]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Dialog
          open={open}
          onClose={closeHandler}
          maxWidth={"sm"}
          PaperProps={{
            sx: {
              borderRadius: "16px",
            },
          }}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
              },
            },
          }}
        >
          <DialogTitle>
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                fontFamily: "Montserrat",
                fontSize: "24px",
                fontWeight: "600",
                lineHeight: "30px",
              }}
            >
              Onboard Developer
              <Button
                sx={{
                  color: "black",
                  fontSize: "1rem",
                }}
                onClick={closeHandler}
              >
                <CloseIcon sx={{ cursor: "pointer" }} onClick={closeHandler} />
              </Button>
            </Grid>
          </DialogTitle>
          <Divider />
          <DialogContent
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Box mb={2}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Lato",
                    fontWeight: "500",
                    fontSize: "15px",
                    lineHeight: "25px",
                  }}
                >
                  Onboarding Date
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "red",
                    fontSize: "12px",
                    lineHeight: "16px",
                    marginLeft: "2px",
                  }}
                >
                  *
                </Typography>
              </Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  views={["year", "month", "day"]}
                  value={formik?.values?.onBoardingDate || ""}
                  sx={{
                    width: "100%",
                  }}
                  onChange={(newValue) => {
                    formik.setFieldValue("onBoardingDate", newValue);
                  }}
                  margin="normal"
                  slotProps={{
                    textField: {
                      readOnly: true,
                    },
                  }}
                  shouldDisableDate={(date) => {
                    return date < new Date(projectStartDate);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      fullWidth
                      {...params}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box mb={2}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Lato",
                    fontWeight: "500",
                    fontSize: "15px",
                    lineHeight: "25px",
                  }}
                >
                  Full Time Developer
                </Typography>
              </Box>
              <Autocomplete
                options={fullTimeDeveloper}
                getOptionLabel={(option) =>
                  `${option?.fullName} (${option?.techStack?.[0]?.name})`
                }
                multiple
                disableCloseOnSelect
                onChange={(event, value) => {
                  setFullTimeBillingData(value);
                  formik.setFieldValue(
                    "fullTimeUserId",
                    value.map((data) => data._id)
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // required
                    sx={{
                      minWidth: "200px  !important",
                    }}
                    name="Fulltime Developer"
                    placeholder="Choose Full Time Developer"
                  />
                )}
              />
            </Box>
            <Box mb={2}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Lato",
                    fontWeight: "500",
                    fontSize: "15px",
                    lineHeight: "25px",
                  }}
                >
                  Full Time Billing Type
                </Typography>
              </Box>
              <TextField
                mb={2}
                select
                sx={{ width: "100%" }}
                required={FullTimeBillingData ? true : false}
                name="fullTimebillingType"
                onChange={formik.handleChange}
                value={formik.values.fullTimebillingType}
              >
                {fullTimebillingType.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </TextField>
            </Box>
            <Box mb={2}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Lato",
                    fontWeight: "500",
                    fontSize: "15px",
                    lineHeight: "25px",
                  }}
                >
                  Boarding Type
                </Typography>
              </Box>
              <TextField
                mb={2}
                select
                sx={{ width: "100%" }}
                required={FullTimeBillingData ? true : false}
                name="boardingType"
                onChange={formik.handleChange}
                value={formik.values.boardingType}
                error={
                  formik.touched.boardingType &&
                  Boolean(formik.errors.boardingType)
                }
                helperText={
                  formik.touched.boardingType && formik.errors.boardingType
                }
              >
                {boardingType.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </TextField>
            </Box>

            <Box mb={2}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Lato",
                    fontWeight: "500",
                    fontSize: "15px",
                    lineHeight: "25px",
                  }}
                >
                  Part Time Developer
                </Typography>
              </Box>
              <Autocomplete
                options={partTimeDeveloper}
                getOptionLabel={(option) =>
                  `${option?.fullName} (${option?.techStack?.[0]?.name})`
                }
                multiple
                disableCloseOnSelect
                onChange={(event, value) => {
                  setPartTimeBillingData(value);
                  formik.setFieldValue(
                    "partTimeUserId",

                    value.map((data) => data._id)
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // required
                    sx={{
                      minWidth: "200px  !important",
                    }}
                    value={developersProject?.firstName}
                    name="Parttime Developer"
                    placeholder="Choose Part Time Developer"
                  />
                )}
              />
            </Box>

            <Box mb={2}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Lato",
                    fontWeight: "500",
                    fontSize: "15px",
                    lineHeight: "25px",
                  }}
                >
                  Part Time Billing Type
                </Typography>
              </Box>
              <TextField
                mb={2}
                select
                sx={{ width: "100%" }}
                name="partTimebillingType"
                onChange={formik.handleChange}
                required={PartTimeBillingData ? true : false}
                value={formik.values.partTimebillingType}
              >
                {partTimebillingType.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </TextField>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box className="mb-4">
              <Button
                onClick={closeHandler}
                // sx={{ color: "blue", width: "90%" }}
                size="medium"
                variant="contained"
                sx={{
                  color: "#4E79FA",
                  backgroundColor: "white",
                  width: "260px",
                  height: "46px",
                  borderRadius: "8px",
                  padding: "0px 36px",
                  // gap: "8px",
                  transition: "background-color 0.3s, color 0.3s", // Add transition for smooth effect
                  "&:hover": {
                    backgroundColor: "#4E79FA",
                    color: "white",
                  },
                }}
              >
                Cancel
              </Button>
            </Box>
            <Box className="mr-8 ml-1 mb-4">
              <Button
                onClick={formik.handleSubmit}
                variant="contained"
                sx={{
                  width: "260px",
                  height: "46px",
                  borderRadius: "8px",
                  padding: "0px 36px",
                  transition: "background-color 0.3s, color 0.3s", // Add transition for smooth effect
                  backgroundColor: "#4E79FA",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#4E79FA",
                  },
                }}
              >
                Onboard Developer
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </form>
    </>
  );
};

export default OnboardDialog;
